import React, {useRef} from 'react'
import {motion, useScroll, useSpring, useTransform, MotionValue} from 'framer-motion'

export default function FirstBlock() {
    function useParallax(value, distance) {
        return useTransform(value, [0,1], [0, -2000])
    }

    function ParallaxText() {
        const ref = useRef(null)
        const {scrollYProgress} = useScroll({target : ref})
        const y = useParallax(scrollYProgress, 0)

        return (
            <motion.h1 className='text-huge m-auto h-auto' style={{ y }}>
                Стань <span className='text-main-orange'>хозяином</span> своей жизни
            </motion.h1>
        )
    }

    return (
        <div
            id='firstblock'
            className='bg-no-repeat m-0 bg-cover w-screen h-screen 
                        overflow-hidden text-left flex items-center 
                        overflow-y-scroll overflow-x-hidden relative' 
            style={{backgroundImage : "url(" + require('./imgs/background-1.png') + ")"}}>

            <motion.div
                className='text-white text-9xl xl:text-8xl m-auto text-center weight-600'>

                <ParallaxText/>
            </motion.div>

        </div>
    )
}