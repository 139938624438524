import React from 'react' 

export default function SecondBlock() {


    return <>
        <div className='bg-main-darkblue h-64 lg:h-96 text-center flex items-center weight-600'>
            <p className='w-2/3 m-auto text-medium'>Мы открыли путь, который научит вас, как стать хозяином своей жизни, создав домашний бизнес.</p>
        </div>
    </>
}