import React, {useEffect, useRef} from 'react'
import {NavLink, useParams} from 'react-router-dom'

export default function Navigation() {
    const {id} = useParams()

    let navRef = useRef(null)

    useEffect(() => {
        function onScroll() {
            navRef.current.classList.toggle("backdrop-blur-sm", window.scrollY > 0)
        }

        document.addEventListener("scroll", onScroll)

    }, [window.scrollY])
    

    return (
        <>
        <div id='nav' ref={navRef} className='fixed top-0 left-0 w-full z-10 overflow-hidden'>
            <div className='xl:w-3/4 w-full flex items-center justify-between mx-auto'>
                <NavLink to={`/${id}`} id='logo' className='mr-5 text-big my-1'>RENTIER</NavLink>
                <div id='nav-buttons' className='flex justify-between items-center ml-auto mr-16 no-underline text-slate-200 text-xl'>
                <NavLink to={`/${id}/about`} className='link text-small'>Обо мне</NavLink>
                </div>
            </div>
            
        </div>
        </>
        
    )
}