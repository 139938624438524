import React, {useRef, useState, useEffect} from 'react'
import EnteringAnimation from './enteringAnimation';
import FirstBlock from './firstBlock'
import Navigation from './navigation'
import SecondBlock from './secondBlock'
import ThirdBlock from './thirdBlock'
import FourthBlock from './fourthBlock';
import FifthBlock from './fifthBlock';
import PersonalInfo from './personalInfo';
import {Routes, Route, useParams, useNavigate} from 'react-router-dom'
import ky from 'ky'

function App() {
  return (
  <>
    <Routes>
      <Route path='/' element={<Main/>} />
      <Route path='/:id' element={<Main/>}/>
      <Route path='/:id/about' element={<About/>}/>
    </Routes>
  </>
    
  );
}

function About() {
  return (
    <>
      <Navigation/>
      <PersonalInfo/>
    </>
  )
}

function Main() {
  const navigate = useNavigate()
  const {id} = useParams()
  console.log(id)
  
  useEffect(() => {
    if (id == undefined || !(/^\d+$/.test(id))) {
	console.log('passed')
	navigate("/420061875")
    } else {
	console.log(id)
    	ky.get(`http://5.252.195.187/api/doesExist/${id}`)
      	  .json()
      	  .then((res) => {
            console.log(res)
            return res === true ? false : navigate("/420061875")
    	})
    }
  }, [])
  return (
  <>
    <div className='w-screen'>
      <EnteringAnimation/>
      <Navigation/>
      <FirstBlock/>
      <SecondBlock/>
      <ThirdBlock/>
      <FourthBlock/>
      <FifthBlock/>
    </div>
  </>
  )
}

export default App;
