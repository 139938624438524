import React from 'react'
import {motion, useScroll} from 'framer-motion'

export default function ThirdBlock() {
    let wordsToAppear = ['Own', 'Your', 'Life']
    const { scrollYProgress} = useScroll()
    
    const wordsContainer = {
        hidden : {opacity : 0},
        visible: (i = 1) => ({
            opacity : 1,
            transition : {
                staggerChildren: 0.5, delayChildren : 0.1 * i
            }
        })
    }

    const wordsStates = {
        visible: {
            opacity : 1,
            y: 0,
            transition : {
                type : "spring",
                damping : 12,
                stiffness: 50,
            },
        },
        hidden : {
            opacity : 0,
            y : 160,
        }
    }
    
    return <>
        <div id='third-block' className='h-300 w-screen'
        style={{
            backgroundImage : "url(" + require('./imgs/background-2.png') + ")", 
            backgroundRepeat : "no-repeat",
            backgroundSize : "cover"}}>

            <div className='h-full w-11/12 lg:w-3/4 relative mx-auto flex flex-col lg:flex-row items-center'>
                <div className='lg:h-2/3 h-1/3 lg:w-1/2 w-full relative mt-20 lg:mt-0 lg:mr-10'>
                    <div className='absolute h-full w-full lg:h-11/12 lg:w-11/12 bg-main-darkblue rounded-xl'></div>
                    <div id='orange-border' className='absolute rounded-xl lg:ml-10 lg:mt-10 lg:h-11/12 lg:w-11/12 h-full w-full text-left flex items-center'>
                        <p className='text-medium  w-3/4 h-11/12 lg:h-3/5 m-auto'>
                            Мы разработали СИСТЕМУ, которую способен использовать каждый. 
                            <br/><br/>
                            Это не продажи, и на это не требуется много времени.
                        </p>
                    </div>
                </div>
                <motion.div 
                    viewport={{once : true, amount : 0.7}}
                    id="oyl-text"
                    className="overflow-hidden flex lg:text-11xl lg:text-left text-center m-auto text-8xl leading-normal lg:ml-auto lg:mr-10 flex-col"
                    initial="hidden"
                    whileInView="visible"
                    variants={wordsContainer}
                    margin="0px"
                >
                {wordsToAppear.map((word,index) => {
                    return <motion.span
                        id='oyl-text'
                        variants={wordsStates}
                        key={index}
                    >
                        {word}
                    </motion.span>
                })}
                </motion.div>
            </div>
        </div>
    </>
}