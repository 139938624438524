import React, {useEffect, useState} from 'react'
import ky from 'ky'
import {useParams} from 'react-router-dom'
import vkontanke from './imgs/vkontakte.svg'
import telegram from './imgs/telegram.svg'

export default function PersonalInfo() {
    const {id} = useParams()
    const [userData, setUserData] = useState()
    const [userPhoto, setUserPhoto] = useState()
    
    function makeSocialMedia(string) {
        let stringArray = string.split(',')
        console.log(stringArray)
        return stringArray.map((socialMedia) => {
            return socialMedia.includes('vk') ? <a className="mx-5" href={socialMedia}><img src={vkontanke}/></a>
                                       : <a className="mx-5" href={socialMedia}><img src={telegram}/></a>
        })
    }

    useEffect(() => {
        async function setInfo() {
            if (id == 'about' || !id) {
                ky.get(`https://ownerofyourlife.ru/api/getinfo/${420061875}`, {
                    headers : {
                        'content-type' : 'application/json'
                    }
                }).json().then((res) => {
                    setUserData(res)
                })
                ky.get(`https://ownerofyourlife.ru/api/getinfo/${420061875}`, {
                    headers : {
                        'content-type' : 'image/jpeg'
                    }
                }).json().then(async (res) => {
                    setUserPhoto(URL.createObjectURL(await res.blob()))
                    console.log(userPhoto)
                })
            } else {
                ky.get(`https://ownerofyourlife.ru/api/getinfo/${id}`, {
                    headers : {
                        'content-type' : 'application/json'
                    }
                }).json().then((res) => {
                    setUserData(res)
                })
                ky.get(`https://ownerofyourlife.ru/api/getinfo/${id}`, {
                    headers : {
                        'content-type' : 'image/jpeg'
                    }
                }).then(async (res) => {
                    //console.log(res.blob())
                    setUserPhoto(URL.createObjectURL(await res.blob()))
                    
                })
            }
        }
        setInfo()
        console.log(userPhoto) 
    }, [])

    //const [photo, aboutInfo, socialMedia] = getUserData()

    return <>
        <div className='whitespace-pre-wrap w-screen min-h-screen h-full bg-cover bg-main-darkblue flex justify-around items-center'>
            <div className='lg:w-5/6 pt-10 sm:pt-40 sm:pb-40 w-full pb-10 lg:flex items-center'> 
		<div className='mt-40 lg:mt-0 lg:w-1/3 w-5/6 h-3/4 2xl:w-1/4 m-auto p-0'>
                    <img src={userPhoto ? userPhoto : null} />
                </div>
                <div className='flex flex-col w-5/6 lg:w-3/5 h-3/4 m-auto lg:mt-0 mt-20 text-white'>
                    <div className='text-left'>
                        <h1 className='text-huge lg:text-left text-center text-main-orange'>Обо мне</h1>
                        <p className=' text-white text-small my-8'>{userData ? userData.aboutInfo : null}</p>
                    </div>
                    <div className='text-left'>
                        <h1 className='text-huge lg:text-left text-center text-main-orange'>Связь со мной</h1>
                        <div className=' text-white text-medium my-8 lg:my-6 lg:mx-0 m-auto w-full lg:w-2/5 flex justify-between'>
                            {userData?.socialMedia ? makeSocialMedia(userData.socialMedia) : ''}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>
}
