import React from 'react' 

export default function FourthBlock() {


    return <>
        <div id='second-block' className='bg-main-darkblue h-96 text-center flex items-center'>
            <p className='w-2/3 m-auto text-medium weight-600'>
                Заинтересовало? <br/> 
                Ниже заполни форму и нажми на кнопку "Получить книгу". Всю книгу читать не обязательно! 
                Прочитай хотя бы первые 4 урока и свяжись со мной!
            </p>
        </div>
    </>
}
