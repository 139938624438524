import {Transition} from 'react-transition-group';
import React, { useEffect, useState, useRef } from 'react'
import {motion} from 'framer-motion'

export default function EnteringAnimation() {
    let [showAnimation, setShowAnimation] = useState(true)
    const nodeRef = useRef(null)

    setTimeout(() => {
      setShowAnimation(false)
    }, 2500)
  
    const svgVariants = {
      hidden : {
        opacity: 0
      },
      visible : {
        opacity : 1
      }
    }

    const pathVariants = {
      hidden : {
        opacity : 0,
        pathLength : 0,
        fill : "rgba(255, 139, 74, 0)"
      },
      visible : {
        pathLength : 1,
        opacity : 1,
        fill : "rgba(255, 139, 74, 1)",
        transition : {
          default : {duration: 2, ease : "easeInOut"},
          fill : {duration: 2, ease: [1, 0, 0.8, 0.9]}
        }
      }
    }

    return (
      <Transition 
        nodeRef={nodeRef}
        in={showAnimation}
        mountOnEnter
        unmountOnExit 
        timeout={{enter : 800, exit : 1500}}
      >
        {state => (
          <div ref={nodeRef} className={`w-screen h-screen fixed z-50 bg-main-darkblue flex items-center first-anim-${state} stroke-main-orange`}>
            <div className='mx-auto w-5/6 lg:w-2/12'>
              <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 318.35 346.04" 
              className={` w-full stroke-2 mx-auto first-amination-svg drop-shadow-xl`} 
              variants={svgVariants}
              initial="hidden" 
              animate="visible"
              >
                <motion.path variants={pathVariants} className="stroke-none" d="M175.06,231.28c-6-.66-11.94-13.95-9.18-26.35,3.36,0,9.13.17,11.53-5.65.65-1.18-.28-1.53-.7-1.18a9.42,9.42,0,0,1-5.53,2.83c-3.38-.63-4.82-5.42-4.22-14.59.73-6.24,2.51-11.26,6.53-13.91a15.42,15.42,0,0,1,7-1.86c13.92.85,15,15.64,14.22,17.93a4.15,4.15,0,0,1,2.43,0c-2.08-7.89-.87-17.36-1.12-19.3-.08-.45-1.55.1-1.77,1.08-.53,2.63-3.42,1.94-3.79,2-9.63-5.78-17.56-3-25,2.24,1-8.06-11.53-10.42-16.23-12.59,26.53.7,27.47-35.94,0-36.47H114v2.35h7.23l.42,74.35-7.65.12V204h25.76l.12-1.89-7.17-.11-.12-38.36,9.06.12c5.27,0,9.07,1.48,11.17,4.59,4.42,6.5,1.62,37.32,4.24,46.59a24.55,24.55,0,0,0,12.35,15.88c5.31,3,10.8,4.35,18.35.47,2.81-1.44,5-2.94,5.94-2.57l3.42-5.55v-10H196C193.59,228,187,233.53,175.06,231.28ZM132.88,161V128h14.61c12.44,2.82,8.07,32.82.22,33Z"/>
                <motion.path variants={pathVariants} d="M99.88,276.75c7.05,7.1,14.83,8.4,23.83,0,3.37-5.91,3.21-12,0-18.18q-3.58-7.06-11.92-9.88c-10.22-5-21.21-4.43-32.73,0-16,7.05-23,17.91-23.3,31.59.67,17.26,10.53,27.55,26.3,33.35L117.53,330c-.53-5.74-4.18-10.82-12.88-14.82L81.53,300.75A38.27,38.27,0,0,1,69.35,283.1c-.72-3.77-1.56-8.64-.17-13.41,3.88-8.57,12.44-14.36,24.35-15.35,10.71.59,14.78,4.94,15,11.47C109.37,272.42,105.47,275.26,99.88,276.75Z"/>
                <motion.path variants={pathVariants} d="M90,289.81a95.2,95.2,0,0,0,12.35,14.12c7.67,4,10.69,8.13,11.65,12.35L97.59,305.16C92.19,298.87,90.25,294,90,289.81Z"/>
                <motion.path variants={pathVariants} d="M85.24,266.69c-4.19,8.08-4.26,16.55,0,25.41L90,300.93A30.44,30.44,0,0,1,78.35,287.6c-2.79-3.5-3.86-10.85-.7-15.44C78.71,270,81.09,268.09,85.24,266.69Z"/>
                <motion.path variants={pathVariants} d="M142.24,280.81c-7.93,2-13.23,5.71-14.65,11.82v4.77c1.76,5.64,9.53,6.7,11.47,0l-.71-2.65c5.26.65,8.36,3.27,8.83,8.29-.64,6.27-4.56,8.45-9.8,9-7.42.22-12.11-2.61-14.56-7.94-2-5-2.22-9.77,0-14.29,2.82-4.75,7.41-7.87,14.12-9Z"/>
                <motion.path variants={pathVariants} d="M135.35,291c.89-7.23,18.71-9.35,22.24,0,1,7.81-.62,11.67-3.18,14.12-.88-6-2.51-14.48-5.65-13.23C146.78,290.66,141.21,290.79,135.35,291Z"/>
                <motion.path variants={pathVariants} d="M159.18,305.16c-2,2.78-4.15,4.59-6.36,5.12-1,5.63,1.93,8.79,6.36,10.94Z"/>
                <motion.path variants={pathVariants} d="M118.24,312.06c8.32,14.44,27.2,14.59,33.76,10.93,1.34-.86,2.33-1.17,2.94-.89-.18,6.77-7.15,9-15.88,10.06C121.46,332.12,117.22,323.74,118.24,312.06Z"/>
                <motion.path variants={pathVariants} d="M153.12,329.57a27.11,27.11,0,0,0-13.47,12.3,10.11,10.11,0,0,0,6.06-2.06A16.33,16.33,0,0,0,153.12,329.57Z"/>
                <motion.path variants={pathVariants} d="M159.18,330v16a14.47,14.47,0,0,1-3.42-8.7Q156.06,331.8,159.18,330Z"/>
                <motion.path variants={pathVariants} d="M115.72,333c-4.24-5.46-14.45-9.13-30.88-10.93a12.46,12.46,0,0,0,9,9.83C97.52,333.22,105.55,333.4,115.72,333Z"/>
                <motion.path variants={pathVariants} d="M55.76,180.4C62.23,192.3,62,204.21,54.18,213c6.55.74,10.95-1.8,11.11-10.76C65.17,192.23,62.13,184.81,55.76,180.4Z"/>
                <motion.path variants={pathVariants} d="M35.65,120.22a28,28,0,0,0,0,23c3.41,4.8,6.74,13.7,10.06,23,1,12.22-.91,17.92-5,18.62a57,57,0,0,0-12.44-21.53c-9.53-13.73-9.77-26.52,0-38.3A25.72,25.72,0,0,1,35.65,120.22Z"/>
                <motion.path variants={pathVariants} d="M69.44,91.46c-13.34.49-22.91,6.8-29.65,17.47q-14,14-26.82,12c-1.53-1.54-2.2-3.27,1.77-6.09-8.1-2.91-11.47-7.75-11.12-14.21-.28-4.31.33-8.2,2.47-11.38C6.87,95.41,10.5,99,17.91,99c6.94-.55,15.1-4.3,23.91-9.79C53,86.42,62.23,87.08,69.44,91.46Z"/>
                <motion.path variants={pathVariants} d="M34.06,76.28c5.72-7.36,14-12.07,26.73-12.27C86.56,66.57,83.38,77.87,102.35,90a68.51,68.51,0,0,0,20.83,0c6.6-.82,16.07-5.52,28.14-13.86a3.83,3.83,0,0,1-.61,2.83c-5.38,10.12-16.4,16.09-32.92,18a74.83,74.83,0,0,1-24,0A42.27,42.27,0,0,1,73.5,87,35.72,35.72,0,0,0,57.88,76.9C50.5,73.88,42.46,74.16,34.06,76.28Z"/>
                <motion.path variants={pathVariants} d="M153.88,63.4c1.35,3.21-.55,6.42-4.14,9.61a50.8,50.8,0,0,1-28,11.92,64.62,64.62,0,0,1-14.11,0c-1.47-1.65-2.22-3-1.86-4a84.86,84.86,0,0,0,21.89,0c11-3.07,19.17-8.87,24.32-17.56A1.27,1.27,0,0,1,153.88,63.4Z"/>
                <motion.path variants={pathVariants} d="M117.88,78c4.93.1,7.79-2.28,7.81-8.08V66c3.18.22,2.43-5.92.93-5.92-1.77,3.4-16.19,1.42-20.92,2.94a9.34,9.34,0,0,0-7,6c-1.18,2.23-1.3,5.14,0,9,.54,3.3,2.19,5.3,4.94,6-1.7-4.58-2.44-8.84,0-12,3.63-3.63,9.92-3.47,14,0C118.76,73.65,117.07,75.31,117.88,78Z"/>
                <motion.path variants={pathVariants} d="M91.65,69.93c-3.59-9-1.83-21.36,4.06-22.24,2.38-.68,3.22-1.54,0-2.88-1.56-.58-1.23-4.4,0-6.77-4.64,1.5-6.53,5.89-7.83,9.83a20.75,20.75,0,0,0,0,12.17C88.18,62.27,90,66.16,91.65,69.93Z"/>
                <motion.path variants={pathVariants} d="M100.85,38c-1.3,1.22-1.2,2.63,0,4.18a9.61,9.61,0,0,0,5.83,3.88,13.86,13.86,0,0,0,10.06,0c5.15-1.83,7.74-5.7,8.82-10.76a19.17,19.17,0,0,0,0-9.44,13.09,13.09,0,0,0-7.68-8,3.16,3.16,0,0,1-.09-4.15c1.87-1.85,1.95-3.79.09-5.82a8.74,8.74,0,0,1-2.73-5Q113,13.7,104.82,16.1C88.7,18.64,79,27.65,76.41,43.72c-.7,8.1.92,14.75,4.33,20.29a79.16,79.16,0,0,0,8.73,10.5c-3.24-4.88-6.25-9.63-7.23-13.05C81.31,51,82,45,82.32,43c2-9.42,8-14,16.42-15.79,8.08.45,12.72,2.71,13.05,7.24-.06,4.09-1.64,6.22-5.47,5.47C104.92,40,103,39.29,100.85,38Z"/>
                <motion.path variants={pathVariants} d="M94.19,0c3.1,3.84,2.92,7.82-.4,12-6.35,7.89-15.9,12.33-20,20-2.41,5.77-2.28,12.51,0,20.07a10.67,10.67,0,0,1-5-6.22,23.63,23.63,0,0,1,0-12.49c2-7.72,6.43-14.1,12.93-19.36Z"/>
                <motion.path variants={pathVariants} d="M85.65,96.69c-13.73-3.44-25.08-.41-34.59,7.66-14.78,14.85-12.67,32.3,0,51.4,16,23.18,32.33,41,30.59,54.12.37,20.64-10.1,31.62-27.47,36.47a29.54,29.54,0,0,1-17.68,0c-8.07-2.65-12.15-9.15-13.68-18.12.56-7.68,7.39-12.77,20.94-15.06q-5.91-6.85-18.11-7.53C8.26,207.61.57,216.88,0,231.51c.32,17.78,9.69,27.31,28.47,28.24,16.81.51,32.59-6.12,47.25-20.47C81.93,230.7,86,226.46,85.65,212a48.45,48.45,0,0,0-4.47-23.06c-8.36-19.89-16.8-39.94-20.24-60l1.18-16C64.57,102.57,72.94,97.64,85.65,96.69Z"/>
                <motion.path variants={pathVariants} d="M58.94,176.69c15,20.9,15.71,37.95,0,50.59-9.11,3.67-17.24,2.85-24.35-2.59-2.28,5.18-1.54,9.17,2.47,11.88,6.77,3.16,14.07,3.12,21.88,0,8.74-2.91,15.3-9.63,18.82-21.64.41-10.79-1.6-19.89-7.41-26.36Z"/>
                <motion.path variants={pathVariants} d="M80.35,143.51a15.18,15.18,0,0,1-7.64,6.12,79.88,79.88,0,0,0,8.35,24.83c3.88-3.06,5-19,2-19.89,4.1-1.42,3.94-5.21,0-8.82Z"/>
                <motion.path variants={pathVariants} d="M69.44,109.75c-1.17,16.78,1.65,26.47,7.38,30.94-.93,3-2.29,5.1-5.53,5.06C64.47,137.57,64.38,119.86,69.44,109.75Z"/>
                <motion.path variants={pathVariants} d="M159.06,88.69C148.85,101.9,126.34,109.51,91,111.28c-5.38,85.93,19,138.28,68.09,162.82V259.78c-44.92-26.32-62-73.59-57.06-137.44,19.14,0,38.15-6.68,57.06-18Z"/>
                <motion.path variants={pathVariants} d="M159.06,59.43c-9.52.17-13.3,1.94-16.91,1.58V57.13h8.45c3.72.09,3.72-4.1,0-4.06h-.95c0,3.8-6.09,3.93-6,0H140c1.91-2.13,9.25-2.48,19-2Z"/>
                <motion.path variants={pathVariants} d="M159.06,48.81a171.86,171.86,0,0,0-20.59.94c-.17-1.75-5.89-4.9-7.53-9.76-1.52-8.48,2.66-11.2,9.09-11.65a20.5,20.5,0,0,1,12-6.24,12.29,12.29,0,0,1,5.53,0c.21-9.14.75-13.46,1.53-14.11V45.87c0,2.62-1.8,1.48-.21,0-.68.71-1,.62-1.2,0,0-.78-.66-1.23-1.89-1.4a2.56,2.56,0,0,0,2-1.17,2.07,2.07,0,0,0,0-2.45,9.87,9.87,0,0,1-1-8.6c.35-4.86-1.49-7.49-5.12-8.29-3.78.34-5.58,2.65-5.91,6.39a11.53,11.53,0,0,0,.93,5.69c1.93,2.4,2,4.25,1.1,5.83-.44,1.9.22,2.51,1.79,2.07l-.82,3h-1A4.84,4.84,0,0,0,145,45.49a3,3,0,0,0,1.82-1.42c-.13-2.53-5.64-.31-5.07-7.94-.14-2.71-.82-4.91-2.56-6.13-2.87-.46-4.71,1-5.47,4.54-.25,2.27-.1,4.24,1,5.47a4,4,0,0,1,.88,5l2.29,3c3.61.3,6.69,0,9.05-1,.29-.34,0-.69-.22-1,1.3-1.05,2.35-1.2,3,0-2.17-.91-1.79-1.54,0-2a8.15,8.15,0,0,0,1.94,2.94,4.51,4.51,0,0,0,2.06-2.65,5,5,0,0,0,2,2.68c1.54.6,2.09-.19,2-1.88l1.32,1.3Z"/>
                <motion.path variants={pathVariants} d="M218.47,276.75c-7.05,7.1-14.83,8.4-23.82,0-3.38-5.91-3.22-12,0-18.18q3.58-7.06,11.91-9.88c10.23-5,21.22-4.43,32.73,0,16,7.05,23,17.91,23.3,31.59-.67,17.26-10.53,27.55-26.3,33.35L200.82,330c.53-5.74,4.19-10.82,12.89-14.82l23.11-14.47A38.12,38.12,0,0,0,249,283.1c.73-3.77,1.56-8.64.18-13.41-3.88-8.57-12.44-14.36-24.36-15.35-10.7.59-14.77,4.94-15,11.47C209,272.42,212.88,275.26,218.47,276.75Z"/>
                <motion.path variants={pathVariants} d="M228.35,289.81A95.2,95.2,0,0,1,216,303.93c-7.67,4-10.69,8.13-11.65,12.35l16.41-11.12C226.17,298.87,228.11,294,228.35,289.81Z"/>
                <motion.path variants={pathVariants} d="M233.12,266.69c4.18,8.08,4.26,16.55,0,25.41l-4.77,8.83A30.44,30.44,0,0,0,240,287.6c2.79-3.5,3.86-10.85.71-15.44C239.64,270,237.27,268.09,233.12,266.69Z"/>
                <motion.path variants={pathVariants} d="M176.12,280.81c7.92,2,13.23,5.71,14.64,11.82v4.77c-1.76,5.64-9.52,6.7-11.47,0l.71-2.65c-5.25.65-8.35,3.27-8.82,8.29.63,6.27,4.55,8.45,9.79,9,7.42.22,12.11-2.61,14.56-7.94,2-5,2.22-9.77,0-14.29-2.82-4.75-7.4-7.87-14.12-9Z"/>
                <motion.path variants={pathVariants} d="M183,291c-.88-7.23-18.71-9.35-22.24,0-1,7.81.62,11.67,3.18,14.12.88-6,2.51-14.48,5.65-13.23C171.57,290.66,177.14,290.79,183,291Z"/>
                <motion.path variants={pathVariants} d="M159.18,305.16c2,2.78,4.14,4.59,6.35,5.12,1,5.63-1.92,8.79-6.35,10.94Z"/>
                <motion.path variants={pathVariants} d="M200.12,312.06c-8.33,14.44-27.2,14.59-33.77,10.93-1.34-.86-2.33-1.17-2.94-.89.18,6.77,7.16,9,15.88,10.06C196.89,332.12,201.14,323.74,200.12,312.06Z"/>
                <motion.path variants={pathVariants} d="M165.24,329.57a27.19,27.19,0,0,1,13.47,12.3,10.07,10.07,0,0,1-6.06-2.06A16.33,16.33,0,0,1,165.24,329.57Z"/>
                <motion.path variants={pathVariants} d="M159.18,330v16a14.46,14.46,0,0,0,3.41-8.7Q162.29,331.8,159.18,330Z"/>
                <motion.path variants={pathVariants} d="M202.63,333c4.25-5.46,14.45-9.13,30.88-10.93a12.46,12.46,0,0,1-8.95,9.83C220.83,333.22,212.8,333.4,202.63,333Z"/>
                <motion.path variants={pathVariants} d="M262.59,180.4c-6.47,11.9-6.19,23.81,1.59,32.64-6.56.74-11-1.8-11.12-10.76C253.18,192.23,256.22,184.81,262.59,180.4Z"/>
                <motion.path variants={pathVariants} d="M282.71,120.22a28.1,28.1,0,0,1,0,23c-3.42,4.8-6.75,13.7-10.06,23-1,12.22.9,17.92,5,18.62a56.69,56.69,0,0,1,12.44-21.53c9.53-13.73,9.76-26.52,0-38.3A26,26,0,0,0,282.71,120.22Z"/>
                <motion.path variants={pathVariants} d="M248.91,91.46c13.35.49,22.91,6.8,29.65,17.47q14,14,26.82,12c1.53-1.54,2.21-3.27-1.76-6.09,8.09-2.91,11.47-7.75,11.12-14.21.27-4.31-.34-8.2-2.48-11.38-.78,6.16-4.4,9.72-11.82,9.79-6.94-.55-15.09-4.3-23.91-9.79C265.37,86.42,256.12,87.08,248.91,91.46Z"/>
                <motion.path variants={pathVariants} d="M284.29,76.28c-5.72-7.36-14-12.07-26.73-12.27C231.79,66.57,235,77.87,216,90a68.45,68.45,0,0,1-20.82,0c-6.61-.82-16.08-5.52-28.15-13.86a3.79,3.79,0,0,0,.62,2.83C173,89.05,184,95,200.56,96.93a74.83,74.83,0,0,0,24,0A42.23,42.23,0,0,0,244.85,87,35.78,35.78,0,0,1,260.47,76.9C267.86,73.88,275.89,74.16,284.29,76.28Z"/>
                <motion.path variants={pathVariants} d="M164.47,63.4c-1.35,3.21.55,6.42,4.15,9.61a50.77,50.77,0,0,0,28,11.92,64.71,64.71,0,0,0,14.12,0c1.46-1.65,2.21-3,1.85-4a84.79,84.79,0,0,1-21.88,0c-11-3.07-19.18-8.87-24.33-17.56A1.27,1.27,0,0,0,164.47,63.4Z"/>
                <motion.path variants={pathVariants} d="M200.47,78c-4.93.1-7.78-2.28-7.81-8.08V66c-3.17.22-2.42-5.92-.92-5.92,1.76,3.4,16.19,1.42,20.91,2.94a9.38,9.38,0,0,1,7,6c1.17,2.23,1.29,5.14,0,9-.54,3.3-2.2,5.3-4.94,6,1.7-4.58,2.43-8.84-.05-12-3.62-3.63-9.91-3.47-14,0C199.59,73.65,201.28,75.31,200.47,78Z"/>
                <motion.path variants={pathVariants} d="M226.71,69.93c3.58-9,1.82-21.36-4.06-22.24-2.38-.68-3.23-1.54,0-2.88,1.55-.58,1.23-4.4,0-6.77,4.64,1.5,6.52,5.89,7.82,9.83a20.75,20.75,0,0,1,0,12.17C230.18,62.27,228.39,66.16,226.71,69.93Z"/>
                <motion.path variants={pathVariants} d="M217.5,38c1.3,1.22,1.21,2.63,0,4.18a9.56,9.56,0,0,1-5.82,3.88,13.86,13.86,0,0,1-10.06,0c-5.16-1.83-7.75-5.7-8.83-10.76a19.28,19.28,0,0,1,0-9.44,13.14,13.14,0,0,1,7.68-8,3.14,3.14,0,0,0,.09-4.15c-1.87-1.85-1.95-3.79-.09-5.82a8.81,8.81,0,0,0,2.74-5q2.13,10.83,10.32,13.23c16.12,2.54,25.79,11.55,28.41,27.62.7,8.1-.91,14.75-4.32,20.29a80,80,0,0,1-8.74,10.5c3.24-4.88,6.25-9.63,7.24-13.05C237,51,236.39,45,236,43c-2-9.42-8-14-16.41-15.79-8.09.45-12.73,2.71-13.06,7.24.06,4.09,1.65,6.22,5.47,5.47C213.44,40,215.31,39.29,217.5,38Z"/>
                <motion.path variants={pathVariants} d="M224.16,0c-3.09,3.84-2.91,7.82.4,12,6.35,7.89,15.9,12.33,20,20,2.42,5.77,2.29,12.51,0,20.07a10.67,10.67,0,0,0,5-6.22,23.63,23.63,0,0,0,0-12.49c-2-7.72-6.43-14.1-12.92-19.36Z"/>
                <motion.path variants={pathVariants} d="M232.71,96.69c13.72-3.44,25.07-.41,34.58,7.66,14.78,14.85,12.68,32.3,0,51.4-16,23.18-32.33,41-30.58,54.12-.38,20.64,10.09,31.62,27.47,36.47a29.51,29.51,0,0,0,17.67,0c8.07-2.65,12.15-9.15,13.68-18.12-.56-7.68-7.38-12.77-20.94-15.06q5.91-6.85,18.12-7.53c17.38,2,25.07,11.25,25.64,25.88-.32,17.78-9.68,27.31-28.47,28.24-16.81.51-32.58-6.12-47.25-20.47-6.21-8.58-10.26-12.82-9.92-27.29a48.21,48.21,0,0,1,4.47-23.06c8.35-19.89,16.79-39.94,20.23-60l-1.17-16C253.78,102.57,245.42,97.64,232.71,96.69Z"/>
                <motion.path variants={pathVariants} d="M259.41,176.69c-15,20.9-15.7,37.95,0,50.59,9.12,3.67,17.24,2.85,24.35-2.59,2.28,5.18,1.54,9.17-2.47,11.88-6.76,3.16-14.07,3.12-21.88,0-8.74-2.91-15.3-9.63-18.82-21.64-.41-10.79,1.6-19.89,7.41-26.36Z"/>
                <motion.path variants={pathVariants} d="M238,143.51a15.16,15.16,0,0,0,7.65,6.12,80.17,80.17,0,0,1-8.36,24.83c-3.88-3.06-5-19-2-19.89-4.1-1.42-3.93-5.21,0-8.82Z"/>
                <motion.path variants={pathVariants} d="M248.91,109.75c1.17,16.78-1.64,26.47-7.38,30.94.94,3,2.3,5.1,5.53,5.06C253.88,137.57,254,119.86,248.91,109.75Z"/>
                <motion.path variants={pathVariants} d="M159.29,88.69c10.21,13.21,32.73,20.82,68.09,22.59,5.38,85.93-19,138.28-68.09,162.82V259.78c44.92-26.32,62-73.59,57.06-137.44-19.14,0-38.14-6.68-57.06-18Z"/>
                <motion.path variants={pathVariants} d="M159.29,59.43c9.52.17,13.3,1.94,16.92,1.58V57.13h-8.46c-3.72.09-3.72-4.1,0-4.06h1c0,3.8,6.08,3.93,6,0h3.61c-1.91-2.13-9.25-2.48-19-2Z"/>
                <motion.path variants={pathVariants} d="M159.29,48.81a171.86,171.86,0,0,1,20.59.94c.17-1.75,5.89-4.9,7.53-9.76,1.52-8.48-2.66-11.2-9.09-11.65a20.5,20.5,0,0,0-12-6.24,12.29,12.29,0,0,0-5.53,0C160.61,13,160.08,8.64,159.29,8V45.87c0,2.62,1.8,1.48.21,0,.69.71,1.05.62,1.21,0,0-.78.65-1.23,1.88-1.4a2.58,2.58,0,0,1-2-1.17,2.07,2.07,0,0,1,0-2.45,9.87,9.87,0,0,0,1-8.6c-.34-4.86,1.49-7.49,5.12-8.29,3.78.34,5.58,2.65,5.91,6.39a11.55,11.55,0,0,1-.92,5.69c-1.94,2.4-2,4.25-1.11,5.83.45,1.9-.22,2.51-1.79,2.07l.82,3h1a4.78,4.78,0,0,1,2.7-1.45,3,3,0,0,1-1.82-1.42c.13-2.53,5.65-.31,5.07-7.94.14-2.71.83-4.91,2.56-6.13,2.87-.46,4.71,1,5.47,4.54.25,2.27.11,4.24-1,5.47a4,4,0,0,0-.88,5l-2.3,3c-3.6.3-6.68,0-9-1-.3-.34,0-.69.22-1-1.3-1.05-2.36-1.2-3.06,0,2.17-.91,1.79-1.54.06-2a8.42,8.42,0,0,1-1.94,2.94,4.44,4.44,0,0,1-2.06-2.65,5.1,5.1,0,0,1-2,2.68c-1.54.6-2.08-.19-2-1.88l-1.33,1.3Z"/>
              </motion.svg>
            </div>
          </div>
        )}
        

      </Transition>
    )
  }