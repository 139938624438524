import React, { useRef,useState } from 'react' 
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import ky from 'ky'

export default function FifthBlock() {
    const [submitText, setSubmitText] = useState('Получить книгу')
    const {id} = useParams() 
    const telegramID = id == 'about' || !id ? 1005328368 : id 

    const {
        register,
        formState: {
            errors
        },
        handleSubmit,
    } = useForm({mode : "onBlur"})

    const onSubmit = async (data) => {
	setSubmitText('Книга отправлена!')
        data.telegramID = telegramID
        await ky.post('https://ownerofyourlife.ru/api/send', {json : data}).json()
    }

    try {
        return <>
        <div id='fifth-block' className='h-full w-full pb-8'
        style={{
            backgroundImage : "url(" + require('./imgs/background-3.png') + ")",
            backgroundRepeat : "no-repeat",
            backgroundSize : "cover"}}>
                <div className='text-center p-10 w-full lg:w-2/3 mx-auto'>
                    <p className='weight-600 text-huge'>ЭЛЕКТРОННАЯ КНИГА</p>
                    <p className='text-medium mt-5'>
                        Заполните небольшую форму и бесплатно получите электронную книгу, 
                        в которой вы сможете больше узнать о нашей системе
                    </p>
                </div>
                
                <form className='lg:h-2/5 h-2/3 xl:w-3/5 lg:w-3/4 w-full m-auto bg-main-darkblue-transparent object-contain
                                flex flex-col text-2xl lg:text-left text-center pb-4' 
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-col'>
                        <div className='m-10 w-5/6 h-20 flex items-center flex-col lg:flex-row justify-between mx-auto'>
                            <p className='lg:w-1/2 pb-6 lg:pb-0'>Каков ваш месячный доход?</p>
                            <div>
                                <select defaultValue={''} className='my-auto'

                                {...register('monthIncome', {
                                    required : "Обязательное поле!"
                                })}
                                >
                                    <option disabled value=""/>
                                    <option value={'Менее 50 тысяч'}>Менее 50 тысяч</option>
                                    <option value={'Более 50 тысяч'}>Более 50 тысяч</option>
                                    <option value={'Более 100 тысяч'}>Более 100 тысяч</option>
                                    
                                </select>
                                <div className='absolute'>
                                    {errors?.monthIncome && <p className='weight-600 text-red-500 text-xl'>{errors?.monthIncome?.message || "Неверный ввод"}</p>}
                                </div>
                            </div>
                            
                        </div>
                        <div className='m-10 w-5/6 h-20 flex items-center flex-col lg:flex-row justify-between mx-auto'>
                            <p className='lg:w-1/2 pb-6 lg:pb-0'>У вас когда-нибудь был бизнес?</p>
                            <div>
                                <select className='my-auto' defaultValue={''}

                                {...register('businessExistence', {
                                    required : "Обязательное поле!"
                                })}

                                >
                                    <option disabled value=""/> 
                                    <option value={'Да'}>Да</option>
                                    <option value={'Нет'}>Нет</option>
                                </select>
                                <div className='absolute'>
                                    {errors?.businessExistence && <p className='weight-600 text-red-500 text-xl'>{errors?.businessExistence?.message || "Неверный ввод"}</p>}
                                </div>
                            </div>
                            
                        </div >
                        <div className='m-10 w-5/6 h-20 flex items-center flex-col lg:flex-row justify-between mx-auto'>
                            <p className='lg:w-1/2 pb-6 lg:pb-0'> Когда вы готовы взяться за новую возможность?</p>
                            <div>
                                <select className='my-auto' defaultValue={''}

                                {...register('readyToDo', {
                                    required : "Обязательное поле!"
                                })}

                                >
                                    <option disabled value=""/>
                                    <option value={'Я не могу сейчас сказать'}>Я не могу сейчас сказать</option>
                                    <option value={'Хоть сейчас'}>Хоть сейчас</option>
                                    <option value={'Недели через две'}>Недели через две</option>
                                    <option value={'Через месяц'}>Через месяц</option>
                                    <option value={'Не раньше чем через три месяца'}>Не раньше чем через три месяца</option>
                                </select>
                                <div className='absolute'>
                                    {errors?.readyToDo && <p className='weight-600 text-red-500 text-xl'>{errors?.readyToDo?.message || "Неверный ввод"}</p>}
                                </div>
                            </div>
                            
                        </div>
                        <div className='m-10 w-5/6 h-20 flex items-center flex-col lg:flex-row justify-between mx-auto'>
                            <p className='lg:w-1/2 pb-6 lg:pb-0'>Как вы нашли мой сайт?</p>
                            <div>
                                <select className='my-auto' defaultValue={''}

                                {...register('websiteDiscovering', {
                                    required : "Обязательное поле!"
                                })}

                                >
                                    <option disabled value=""/>
                                    <option value={'Через социальные сети'}>Через социальные сети</option>
                                    <option value={'Через визитку'}>Через визитку</option>
                                    <option value={'Через знакомых'}>Через знакомых</option>
                                </select>
                                <div className='absolute'>
                                    {errors?.websiteDiscovering && <p className='weight-600 text-red-500 text-xl'>{errors?.websiteDiscovering?.message || "Неверный ввод"}</p>}
                                </div>
                            </div>
                            
                        </div>
                        <div className='m-10 w-5/6 h-20 flex items-center flex-col lg:flex-row justify-between mx-auto'>
                            <p className='lg:w-1/2 pb-6 lg:pb-0'>Сколько часов в неделю вы готовы уделять своему делу?</p>
                            <div>
                                <select className='my-auto' defaultValue={''}

                                {...register('hoursPerWeek', {
                                    required : "Обязательное поле!"
                                })}

                                >
                                    <option disabled value=""/>
                                    <option value={'Не более четырех'}>Не более четырех</option>
                                    <option value={'Восемь'}>Восемь</option>
                                    <option value={'Шестнадцать и более'}>Шестнадцать и более</option>
                                </select>
                                <div className='absolute'>
                                    {errors?.hoursPerWeek && <p className='weight-600 text-red-500 text-xl'>{errors?.hoursPerWeek?.message || "Неверный ввод"}</p>}
                                </div>
                            </div>
                            
                        </div>
                        <div className='m-10 w-5/6 h-20 flex items-center flex-col lg:flex-row justify-between mx-auto'>
                            <p className='lg:w-1/2 pb-6 lg:pb-0'>Ваша электронная почта</p>
                            <div>
                                <input className='my-auto'
                                    {
                                        ...register('q_email', {
                                            required : "Поле обязательно к заполнению",
                                            pattern : {
                                                value : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                message : "Невалидный формат почты!"
                                            }
                                        }
                                        ) 
                                    }
                                />
                                <div className='absolute'>
                                    {errors?.q_email && <p className='weight-600 text-red-500 text-xl'>{errors?.q_email?.message || "Неверный ввод"}</p>}
                                </div>
                            </div>
                            

                        </div>
                        <div className='m-10 w-5/6 h-20 flex items-center flex-col lg:flex-row justify-between mx-auto'>
                            <p className='lg:w-1/2 pb-6 lg:pb-0'>Ваш номер телефона</p>
                            <div>
                                <input className='my-auto'
                                {
                                    ...register('q_number', {
                                        required : "Поле обязательно к заполнению",
                                        pattern : {
                                            value : /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/g,
                                            message : "Неверный формат номера!"
                                        }
                                    }
                                    ) 
                                }
                                />
                                <div className='absolute'>
                                    {errors?.q_number && <p className='weight-600 text-red-500 text-xl'>{errors?.q_number?.message || "Неверный ввод"}</p>}
                                </div>
                            </div>
                            
                        </div>
                        <div className='m-10 w-5/6 h-20 flex items-center flex-col lg:flex-row justify-between mx-auto'>
                            <p className='lg:w-1/2 pb-6 lg:pb-0'>Ваше имя</p>
                            <div>
                                <input className='my-auto'
                                {
                                    ...register('q_name', {
                                        required : "Поле обязательно к заполнению",
                                        pattern : {
                                            value : /[a-zA-Zа-яА-Я]/g,
                                            message : "Только буквы!"
                                        }
                                    }
                                    ) 
                                }
                                />
                                <div className='absolute'>
                                    {errors?.q_name && <p className='weight-600 text-red-500 text-xl'>{errors?.q_name?.message || "Неверный ввод"}</p>}
                                </div>
                            </div>
                            
                        </div>
                        <div className='m-10 w-5/6 h-20 flex items-center flex-col lg:flex-row justify-between mx-auto'>
                            <p className='lg:w-1/2 pb-6 lg:pb-0'>Ваша фамилия</p>
                            <div>
                                <input className='my-auto'
                                {
                                    ...register('q_surname', {
                                        required : "Поле обязательно к заполнению",
                                        pattern : {
                                            value : /[a-zA-Zа-яА-Я]/g,
                                            message : "Только буквы!"
                                        }
                                    }
                                    ) 
                                }
                                />
                                <div className='absolute'>
                                    {errors?.q_surname && <p className='weight-600 text-red-500 text-xl'>{errors?.q_surname?.message || "Неверный ввод"}</p>}
                                </div>
                            </div>
                            
                        </div>
                        <input 
                            value={submitText} 
                            className='bg-main-orange m-5 px-6 h-16 py-3 text-3xl text-main-darkblue mx-auto' 
                            type='submit'  
                        />
                    </div>
                </form>
        </div>
    </>
    } catch(e) {
        console.log(e)
    }
    
}
